










































import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Ticket from '@improve/common-utils/src/model/Ticket';
import fileIcon from '../../assets/file-icon.svg';
import BaseMenu from '../widgets/BaseMenu.vue';
import BaseModal from '../widgets/BaseModal.vue';
import BaseAddButton from '../widgets/BaseAddButton.vue';
import BaseFormControl from '../../helpers/BaseFormControl';
import BaseControlWrapper from './BaseControlWrapper.vue';
import BaseTicketFileUpload from '../widgets/BaseTicketFileUpload.vue';

@Component({
  name: 'BaseFilesContainer',
  components: {
    BaseModal,
    BaseControlWrapper,
    BaseMenu,
    BaseAddButton,
    BaseTicketFileUpload
  }
})
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default class BaseFilesContainer extends BaseFormControl {
  @Prop({ default: () => [] }) readonly files!: Array<any>;

  @Prop({ default: null }) readonly ticket!: Ticket;

  @Action removeFile!: ActionMethod;

  fileIcon = fileIcon;

  allFiles: Array<any> = [];

  uploadedFiles = {};

  @Watch('ticket.files')
  updateAllFiles() {
    this.allFiles = [...this.ticket.files];
  }

  created(): void {
    super.created();
    this.allFiles = this.ticket.files;
  }

  openInputFiles(): void {
    document.getElementById('fileInput')!.click();
  }

  filesChanged(file: any): void {
    this.allFiles = [
      ...this.allFiles,
      ...[file]
    ];
  }

  removeUploadedFile(file: { id: string; }): void {
    this.removeFile({ ticketId: this.ticket.canonicalId, fileId: file.id });
    this.allFiles = this.allFiles.filter((element) => element.id !== file.id);
    this.$emit('removeFile', this.allFiles);
  }
}
