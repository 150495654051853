






























































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '../widgets/BaseButton.vue';

@Component({
  name: 'BaseTicketActions',
  components: {
    BaseButton
  }
})
export default class BaseTicketActions extends Vue {
  @Prop({ default: false }) readonly inProgress!: boolean;

  @Prop({ default: false }) readonly submitToReview!: boolean;

  @Prop({ default: false }) readonly submitToDraft!: boolean;

  @Prop({ default: false }) readonly save!: boolean;

  @Prop({ default: false }) readonly cancel!: boolean;

  @Prop({ default: false }) readonly archive!: boolean;
}
