




















































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import InlineSvg from 'vue-inline-svg';

@Component({
  name: 'BaseChips',
  components: {
    InlineSvg
  }
})
export default class BaseChips extends Vue {
  @Prop({ default: () => [] }) chipOptions!: Array<MenuOption>;

  @Prop({ default: false }) showClose!: boolean;

  @Prop({ default: false }) showClearAll!: boolean;

  clearAllChip: MenuOption = {
    title: this.$t('baseChips.clear').toString(),
    value: 'clear',
    icon: 'close-icon-blue.svg'
  };

  selectInfo(info: string): void {
    this.$emit('showInfo', info);
  }

  removeInfo(id: string): void {
    this.$emit('removeInfo', id);
  }

  removeInfos(): void {
    this.$emit('clearAll');
  }
}
