














































import Vue from 'vue';
import { ActionMethod } from 'vuex';
import { Action, Getter } from 'vuex-class';
import { Component } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import BaseTicketActions
  from '@improve/common-components/src/components/ticket/BaseTicketActions.vue';
import ImproveTicketStatus from '@improve/common-utils/src/types/ImproveTicketStatus';
import Ticket from '@improve/common-utils/src/model/Ticket';
import User from '@improve/common-utils/src/model/User';
import BaseTicket from '@/components/ticket/BaseTicket.vue';
import TicketDelegationType from '@improve/common-utils/src/types/TicketDelegationType';

@Component({
  name: 'CreateTicket',
  components: {
    BaseTicket,
    BaseTicketActions,
    BaseButton
  },
  beforeRouteEnter(
    to: Route,
    from: Route,
    next: NavigationGuardNext
  ) {
    next(async (vm: Vue & { createTicket?: ActionMethod, ticket?: Ticket }) => {
      const scratchTicket = new Ticket();
      scratchTicket.status = ImproveTicketStatus.SCRATCH;
      vm.ticket = await vm.createTicket!(scratchTicket);
    });
  }
})
export default class CreateTicket extends Vue {
  @Getter currentUser?: User;

  @Action createTicket!: ActionMethod;

  @Action updateTicket!: ActionMethod;

  @Action updateTicketStatus!: ActionMethod;

  readonly assignOptions: Array<{ label: string; value: string }> = [
    {
      label: this.$t('form.ticketAssignToTeam').toString(),
      value: TicketDelegationType.TEAM
    },
    {
      label: this.$t('form.ticketAssignToTopic').toString(),
      value: TicketDelegationType.TOPIC
    },
    {
      label: this.$t('form.ticketAssignToUser').toString(),
      value: 'USER'
    }
  ];

  draftStatus: ImproveTicketStatus = ImproveTicketStatus.DRAFT;

  ImproveTicketStatus = ImproveTicketStatus;

  ticket: Ticket | null = null;

  isSaving = false;

  get userFullName(): string {
    return `${this.currentUser?.firstName} ${this.currentUser?.lastName}`;
  }

  get baseTicket(): any {
    return this.$refs.baseTicket as any;
  }

  async created(): Promise<void> {
    this.ticket = new Ticket();
  }

  async save(toStatus: ImproveTicketStatus): Promise<void> {
    const valid = await this.baseTicket.validate();
    if (!valid) {
      return;
    }
    this.isSaving = true;
    const newTkt = await this.updateTicket(this.baseTicket.prepareTicket());
    await this.updateTicketStatus({
      ticket: newTkt,
      fromStatus: newTkt.status,
      toStatus
    });
    this.isSaving = false;

    if (toStatus === ImproveTicketStatus.READY_TO_REVIEW) {
      this.goToThankYou(newTkt.canonicalId);
    } else {
      this.goBack();
    }
  }

  goToThankYou(ticketId: string): void {
    this.$router.push({
      name: 'TicketThanks',
      params: { id: ticketId }
    });
  }

  goBack(): void {
    this.$router.push({ name: 'MyImprovesTab' });
  }
}
