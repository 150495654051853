





































import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import GenericFileIcon from './media/GenericFileIcon.vue';
import BaseMenu from './BaseMenu.vue';
import BasePill from './BasePill.vue';
import fileIcon from '../../assets/file-icon.svg';
import videoIcon from '../../assets/video-icon.svg';

@Component({
  name: 'BaseFileCard',
  inheritAttrs: false,
  components: {
    BasePill,
    BaseMenu,
    GenericFileIcon
  }
})
export default class BaseFileCard extends Vue {
  @Prop({ default: () => [] }) readonly menuOptions!: MenuOption[];

  @Prop({ default: '' }) readonly pillLabel!: string;

  @Prop({ default: '' }) readonly fileSource!: string;

  @Prop({ default: '' }) readonly title!: string;

  @Prop({ default: '' }) readonly subtitle!: string;

  @Prop({ default: '' }) readonly contentType!: string;

  get getFileSource(): string {
    if (this.fileSource.includes('text')) {
      return fileIcon;
    }
    if (this.fileSource.includes('video')) {
      return videoIcon;
    }
    return this.fileSource;
  }

  getFileExtension(filename: string): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(filename)![1]?.toUpperCase() || '';
  }
}
