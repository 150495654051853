





































































































































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseTextInput from '@improve/common-components/src/components/core/BaseTextInput.vue';
import BaseTextArea from '@improve/common-components/src/components/core/BaseTextArea.vue';
import BaseSelect from '@improve/common-components/src/components/core/BaseSelect.vue';
import BaseTasks from '@improve/common-components/src/components/ticket/BaseTasks.vue';
import BaseAutoComplete from '@improve/common-components/src/components/core/BaseAutoComplete.vue';
import BaseRadioGroup from '@improve/common-components/src/components/core/BaseRadioGroup.vue';
import BaseDatePicker from '@improve/common-components/src/components/core/BaseDatePicker.vue';
import BaseAddButton from '@improve/common-components/src/components/widgets/BaseAddButton.vue';
import BaseExternalFields
  from '@improve/common-components/src/components/widgets/BaseExternalFields.vue';
import BaseChips from '@improve/common-components/src/components/widgets/BaseChips.vue';
import DeletionConfirmation
  from '@improve/common-components/src/components/widgets/DeletionConfirmation.vue';
import BaseFilesContainer
  from '@improve/common-components/src/components/core/BaseFilesContainer.vue';
import Ticket from '@improve/common-utils/src/model/Ticket';
import { FileModel } from '@improve/common-utils/src/types/FileModel';
import User from '@improve/common-utils/src/model/User';
import TeamSelector from '@/components/widgets/TeamSelector.vue';
import UserSelector from '@/components/widgets/UserSelector.vue';
import Team from '@improve/common-utils/src/model/Team';
import Topic from '@improve/common-utils/src/model/Topic';
import Organization from '@improve/common-utils/src/model/Organization';
import { FieldGroup } from '@improve/common-utils/src/model/DynamicField';
import TicketDelegationType from '@improve/common-utils/src/types/TicketDelegationType';
import TaskUpdateParams from '@improve/common-utils/src/types/TaskUpdateParams';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';

@Component({
  name: 'BaseTicket',
  components: {
    BaseTextInput,
    BaseSelect,
    BaseTextArea,
    BaseRadioGroup,
    BaseAutoComplete,
    BaseExternalFields,
    TeamSelector,
    BaseDatePicker,
    UserSelector,
    BaseTasks,
    DeletionConfirmation,
    BaseChips,
    BaseFilesContainer,
    BaseAddButton
  }
})
export default class BaseTicket extends Vue {
  @Getter currentUser?: User;

  @Getter currentOrganization?: Organization;

  @Getter userById!: (uId: string) => User | null;

  @Getter externalFields!: Array<FieldGroup>;

  @Prop({ default: true }) readonly enableTasks!: boolean;

  @Prop({ default: null }) readonly ticket!: Ticket;

  @Prop({ required: true }) readonly assignOptions!: Array<{ label: string; value: string }>;

  @Action fetchUserTeams!: ActionMethod;

  @Action fetchTopicsByContext!: ActionMethod;

  @Action updateTicket!: ActionMethod;

  @Action fetchExternalFields!: ActionMethod;

  selectedDelegateType: string | null = null;

  selectedDelegateValue: string | null = null;

  links: Array<string> = [];

  userTeams: Array<Team> = [];

  orgTeams: Array<Team> = [];

  topics: Array<Topic> = [];

  isLoadingDelegateValues = false;

  deadLine: string | null = null;

  implementationDate: string | null = null;

  initialized = false;

  enabledFieldGroupIds: Array<string> = [];

  toBeDeletedExternalField: string | null = null;

  showFiles = false;

  askRemoveInfo = false;

  chipOptions: Array<MenuOption> = [];

  chipFilteredOptions: Array<MenuOption> = [];

  mounted(): void {
    if (this.$route.name === 'CreateTicket') {
      const input = document.getElementById('title');
      input?.focus();
    }
  }

  getChipOptions(): Array<MenuOption> {
    return new Array<MenuOption>(
      {
        title: this.$t('baseChips.economicEfficiency').toString(),
        value: 'ticket-benefits',
        icon: 'benefits.svg'
      },
      {
        title: this.$t('baseChips.b2bCustomer').toString(),
        value: 'swisscom-b2b-fields',
        icon: 'graph.svg'
      },
      {
        title: this.$t('baseChips.files').toString(),
        value: 'files',
        icon: 'file-icon.svg'
      }
    );
  }

  openDeletionConfirmation(groupId: string): void {
    this.toBeDeletedExternalField = groupId;
    this.askRemoveInfo = true;
  }

  removeExternalField(): void {
    const fieldGroup = this.externalFields.find(
      (group) => group.groupId === this.toBeDeletedExternalField
    );
    const meta = this.ticket.meta as any;
    fieldGroup?.fields.forEach((field) => {
      meta[field.ref] = null;
    });
    this.ticket.meta = meta;
    this.$emit('updateTicket', this.ticket);
    this.askRemoveInfo = false;
    this.enabledFieldGroupIds = this.enabledFieldGroupIds.filter(
      (id) => id !== this.toBeDeletedExternalField
    );
    this.chipOptions.forEach((option) => {
      if (option.value === this.toBeDeletedExternalField) this.chipFilteredOptions.push(option);
    });
  }

  addNewFile(file: FileModel): void {
    this.ticket.files.push(file);
  }

  updateTicketFiles(files: Array<any>): void {
    this.ticket.files = files;

    if (!files.length) {
      this.showFiles = false;
      this.chipOptions.forEach((option) => {
        if (option.value === 'files') {
          this.chipFilteredOptions.push(option);
        }
      });
    }
  }

  selectInfo(info: string): void {
    if (info === 'files') {
      this.showFiles = true;
    } else {
      this.enabledFieldGroupIds.push(info);
    }
    this.chipFilteredOptions = this.chipFilteredOptions.filter((option) => option.value !== info);
  }

  changeDeadline(date: string): void {
    this.deadLine = this.$date(date, 'DD.M.YYYY').endOf('day').format('DD.M.YYYY');
    this.ticket.deadLine = this.$date(date).endOf('day').toDate();
  }

  changeImplementationDate(date: string): void {
    this.implementationDate = this.$date(date, 'DD.M.YYYY').endOf('day').format('DD.M.YYYY');
    this.ticket.meta.implementationDate = this.$date(this.$date(date, 'DD.M.YYYY').endOf('day').toDate()).format('YYYY-MM-DD');
  }

  async created(): Promise<void> {
    this.initTicketLinks();
    this.selectedDelegateType = this.ticket.getDelegateType() || TicketDelegationType.DEFAULT;
    await this.fetchDataForDelegateType(this.selectedDelegateType);
    this.selectedDelegateValue = this.$route.params.teamId || this.ticket.getDelegateID();
    this.initialized = true;
    this.deadLine = this.ticket.deadLine ? this.$date(this.ticket.deadLine).format('DD.M.YYYY') : null;
    this.implementationDate = this.ticket.meta.implementationDate
      ? this.$date(this.ticket.meta.implementationDate).format('DD.M.YYYY') : null;
    this.chipOptions = this.getChipOptions();
    await this.fetchExternalFields(this.ticket.type);
    this.checkFilteredOptions();
  }

  checkFilteredOptions(): void {
    this.chipFilteredOptions = this.chipOptions;
    const meta = this.ticket.meta as any;
    this.externalFields.forEach((fieldGroup) => {
      fieldGroup.fields.every((field) => {
        if (meta[field.ref]) {
          this.enabledFieldGroupIds.push(fieldGroup.groupId);
          this.chipFilteredOptions = this.chipFilteredOptions.filter(
            (option) => option.value !== fieldGroup.groupId
          );
          return false; // Don't need to check further
        }
        return true;
      });
    });
    if (this.ticket.files.length > 0) {
      this.showFiles = true;
      this.chipFilteredOptions = this.chipFilteredOptions.filter((option) => option.value !== 'files');
    }
  }

  async fetchDataForDelegateType(delegateType: string): Promise<void> {
    this.isLoadingDelegateValues = true;
    switch (delegateType) {
      case TicketDelegationType.TEAM:
        await this.initTeams();
        break;
      case TicketDelegationType.TOPIC:
        await this.initTopics();
        break;
      default:
        break;
    }
    this.isLoadingDelegateValues = false;
  }

  initTicketLinks(): void {
    const links = this.ticket.getLinks();
    this.links = links && links.length ? links : [''];
  }

  initTeams(): Promise<void> {
    return this.fetchUserTeams(this.currentUser?.id).then((teams) => {
      this.userTeams = teams;
    })
      .catch(() => {
        this.userTeams = [];
      })
      .finally(() => {
        this.orgTeams = this.currentOrganization ? this.currentOrganization.teams : [];
      });
  }

  async initTopics(): Promise<void> {
    this.topics = await this.fetchTopicsByContext();
  }

  addLink(): void {
    this.links.push('');
  }

  removeLink(index: number): void {
    this.links.splice(index, 1);
  }

  delegateTypeChanged(selectedDelegateType: string): void {
    this.selectedDelegateValue = null;
    this.fetchDataForDelegateType(selectedDelegateType);
  }

  setDelegate(delegate: Team | Topic | User): void {
    if (delegate && delegate.id) {
      this.selectedDelegateValue = delegate.id;
    }
  }

  prepareTicket(): Ticket {
    // Prepare ticket for saving
    this.ticket.setLinks(this.links);
    this.ticket.name = this.ticket.title;
    this.ticket.creatorTeam = this.currentUser?.unit;
    if (this.selectedDelegateType === 'USER') this.changeDelegateUserToTeam();
    this.ticket.delegate = `${this.selectedDelegateType}:${this.selectedDelegateValue}`;
    this.prepareExternalFields();
    return this.ticket;
  }

  // We don't want to store the delegate type 'USER' in the database.
  // The user should instead be stored in 'ticket.assignee' and
  // the ticket should be delegated to the team of the assigned user.
  changeDelegateUserToTeam(): void {
    this.selectedDelegateType = TicketDelegationType.TEAM;
    const user = this.userById(this.selectedDelegateValue!);
    this.selectedDelegateValue = user?.unit || null;
    this.ticket.assignee = user?.id;
  }

  prepareTasks(): TaskUpdateParams {
    const component: any = this.$refs.baseTasks;
    return component.prepareTasks();
  }

  prepareExternalFields(): void {
    const component: any = this.$refs.externalFieldsCtrl;
    if (component) {
      this.ticket.meta = { ...this.ticket.meta, ...component.prepareExternalFields() };
    }
  }

  validate(): Promise<any> {
    const component: any = this.$refs.obs;
    return component.validate();
  }
}

