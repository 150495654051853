






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseAutoComplete from '@improve/common-components/src/components/core/BaseAutoComplete.vue';
import Team from '@improve/common-utils/src/model/Team';

type TeamOptionType = {
  id?: string;
  name?: string;
  header?: string;
  divider?: boolean;
};

@Component({
  name: 'TeamSelector',
  components: {
    BaseAutoComplete
  }
})
export default class TeamSelector extends Vue {
  @Prop({ default: () => [] }) readonly userTeams!: Array<Team>;

  @Prop({ default: () => [] }) readonly orgTeams!: Array<Team>;

  get options(): Array<TeamOptionType> {
    const options = [];
    if (this.userTeams.length) {
      options.push(
        { header: this.$t('page.teams.myTeams') },
        ...(this.userTeams as Array<any>)
      );
    }

    if (this.orgTeams.length) {
      options.push(
        { header: this.$t('page.teams.allTeams') },
        ...(this.orgTeams as Array<any>)
      );
    }
    return options;
  }
}
