
































































import { Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import Vue from 'vue';
import convert from 'convert-units';
import FileUpload from 'vue-upload-component';
import { VUFile } from '@improve/common-utils/src/types/VUFile.d';
import { UploadState } from '@improve/common-utils/src/types/UploadState';
import { FileModel } from '@improve/common-utils/src/types/FileModel';
import uploadIcon from '../../assets/upload-icon.svg';
import imageIcon from '../../assets/image-icon.svg';
import videoIcon from '../../assets/video-icon.svg';
import presentationIcon from '../../assets/presentation-icon.svg';
import closeIcon from '../../assets/close-icon.svg';
import BaseFileCard from './BaseFileCard.vue';

@Component({
  name: 'BaseUpload',
  inheritAttrs: false,
  components: {
    BaseFileCard,
    FileUpload
  }
})
export default class BaseUpload extends Vue {
  // Random id to allow multiple upload elements on one page
  @Prop({ default: () => Math.random().toString() }) readonly inputId!: string;

  @Prop({ default: 'default' }) readonly allowedExtensions!: string;

  @Prop({ default: '' }) readonly displayName!: string;

  @Prop({ default: '' }) readonly objectId!: string;

  @Prop({ default: false }) uploadingTicketFile!: boolean;

  @Prop({ default: false }) fromTicket!: boolean;

  @Prop({ default: null }) readonly ticketFile!: any;

  @Getter uploadStateByInputId!: Map<string, UploadState>;

  @Action uploadLargeFile!: ActionMethod;

  icons = {
    image: imageIcon,
    video: videoIcon,
    presentation: presentationIcon,
    upload: uploadIcon,
    close: closeIcon
  };

  imageExtensions = '.jpeg,.jpg,.gif,.png,.webp';

  videoExtensions = '.mp4,.avi,.m4v,.mpg,.mpeg';

  audioExtensions = '.mp3,.wav';

  officeExtensions = '.doc,.docx,.dotx,.pptx,.potx,.ppt,.pdf,.xlsx,.xltx,.zip,.rar,.7z';

  files: File[] = [];

  file: VUFile | null = null;

  uploadError = '';

  get initialState(): boolean {
    return !this.file?.size;
  }

  get uploadProgress(): number {
    return this.uploadStateByInputId.get(this.inputId)?.uploadProgress || 0;
  }

  get showFileCard(): boolean {
    return this.uploadState
  && ((this.uploadingTicketFile && this.fromTicket)
    || (!this.uploadingTicketFile && !this.fromTicket));
  }

  get acceptedExtensions(): string {
    switch (this.allowedExtensions) {
      case 'images':
        return this.imageExtensions;
      case 'office':
        return this.officeExtensions;
      case 'audio':
        return this.audioExtensions;
      case 'video':
        return this.videoExtensions;
      default:
        return `${this.imageExtensions},${this.videoExtensions},${this.officeExtensions},${this.audioExtensions}`;
    }
  }

  get fileIndicatorIcon(): string {
    if (this.file?.type.startsWith('video') || this.file?.type.startsWith('audio')) {
      return this.icons.video;
    }
    if (this.file?.type.startsWith('image')) {
      return this.icons.image;
    }
    if (this.file?.type.startsWith('application')) {
      return this.icons.presentation;
    }
    return this.icons.upload;
  }

  get uploadState(): boolean {
    return !!(this.file?.size && this.uploadProgress !== 100);
  }

  get initialText(): string {
    const fileType = this.$t(`project.video.${this.displayName || 'file'}`);
    return `${this.$t('project.video.upload')} ${fileType}`;
  }

  async upload(uploadedFile: VUFile[]): Promise<void> {
    [this.file] = uploadedFile;
    if (!this.file) {
      return;
    }
    const mediaObject: FileModel = await this.uploadLargeFile({
      inputId: this.inputId,
      objectId: this.objectId,
      category: this.displayName,
      file: this.file
    });
    this.$emit('uploadFinished', mediaObject);
  }

  async uploadTicketFile(): Promise<void> {
    if (!this.ticketFile) {
      return;
    }
    const uploadedFile = {} as VUFile;
    uploadedFile.file = this.ticketFile;
    uploadedFile.size = this.ticketFile.size;
    uploadedFile.name = this.ticketFile.name;
    uploadedFile.success = false;
    uploadedFile.active = false;
    uploadedFile.type = this.ticketFile.type;
    this.file = uploadedFile;
    const mediaObject: FileModel = await this.uploadLargeFile({
      inputId: this.inputId,
      objectId: this.objectId,
      category: this.displayName,
      file: this.file
    });
    mediaObject.contentType = uploadedFile.type;
    this.$emit('uploadFinished', mediaObject);
  }

  created(): void {
    if (this.uploadingTicketFile) {
      this.uploadTicketFile();
    }
  }

  clearFile(): void {
    const vueUploadComp: FileUpload = this.$refs.upload as FileUpload;
    vueUploadComp.clear();
    this.file = null;
    this.uploadError = '';
    this.$emit('uploadFinished', this.file);
  }

  getMegaBytes(bytes: number): string {
    return new Intl.NumberFormat(navigator.language, {
      maximumSignificantDigits: 3
    })
      .format(convert(bytes).from('B').to('Mb'));
  }

  inputFilter(
    newFile: File,
    oldFile: File,
    prevent: () => boolean
  ): boolean {
    if (newFile
      && (!oldFile || newFile !== oldFile)
      && newFile.size > convert(20000).from('Mb').to('B')) {
      this.uploadError = this.$t('error.fileTooBig', '2Gb').toString();
      return prevent();
    }
    this.uploadError = '';
    return true;
  }
}
